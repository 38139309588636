// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
a:link {
    text-decoration: none;
}

.navbar-toggler {
    border: #818281;
    color: #3b3434;
}

.navbar-toggler:enabled {
    background-color: #818281;
}
.navbar-toggler-icon {
    background-color: #818281;
}


